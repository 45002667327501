import Vue from 'vue'
import moment from 'moment'
const mixin = {
    methods: {
        makeToast(text) {
            this.$bvToast.toast(text, {
                title: 'ATENÇÃO',
                variant: 'danger',
                toaster: 'b-toaster-top-center',
                solid: true,
            })
        },
        toMonetary(value) {
            const tempValue = value * 1;
            return tempValue.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
        parseDate(date) {
            if (date) {
                return moment(date).format("DD/MM/YYYY");
            } else {
                return "";
            }
        },
    }
}

Vue.mixin(mixin)