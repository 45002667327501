export default
    [
        {
            path: '/checkout/:token',
            name: 'config.auto',
            meta: {
                icon: 'file-code',
                title: 'Automações'
            },
            component: () => import('@/views/checkout/Index')
        },
        {
            path: '/testeboleto',
            name: 'config.auto',
            meta: {
                icon: 'file-code',
                title: 'Automações'
            },
            component: () => import('@/views/checkout/IndexTest')
        }

    ]