export default
    [
        {
            path: '/lead',
            name: 'lead',
            meta: {
                icon: 'file-code',
                title: 'Automações'
            },
            component: () => import('@/views/lead/Index')
        }

    ]