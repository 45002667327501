import { _ } from 'core-js';
import Http from 'Http';
import HttpGtw from 'HttpGtw';
import ApiFinanceiro from 'ApiFinanceiro';
import axios from 'axios'

const state = {
    clientData: '',
    unityData: '',
    invoiceId: ''
}
const getters = {

}
const actions = {
    async init({ commit }, token) {

        //https://checkout.apiserver.com.br/checkout/f504361b-f730-4748-ba63-c6dc4a2b94ee_de6cf380-1b0e-43c3-8fff-0dff8142b257_o80989088

        try {



            const [urlId, invoiceId, outro] = token.split('_')

            console.log('initi call')

            const response = await Http().get(`/url-checkout/${urlId}`)

            console.log('response', response)

            const url = response.data.payload.url_api

            console.log('invoiceId =>', invoiceId)

            const clientData = await axios.get(`${url}/token-invoice/${invoiceId}`)

            console.log('clientData', clientData)

            const unityId = clientData.data.payload.invoice[0].unity_id

            const unityData = await ApiFinanceiro().get(`/gateway-unity/${unityId}`)

            console.log('unityData', unityData)

            commit('SET', { state: "clientData", data: clientData.data.payload })

            commit('SET', { state: "unityData", data: unityData.data.payload })

            commit('SET', { state: "invoiceId", data: invoiceId })


        } catch (response) {
            console.log(response)
        }

    },
    async generate_bank_ticket({ commit }, { data }) {
        // async generate_bank_ticket({ commit }, {data, bank_gateway}) {    

        const response = await axios.put(`/invoice/confirm-ticket`, data)

        // try {

        //     const response = await axios.post(`${bank_gateway}/register-ticket`, data)           

        //     console.log(response)            

        //     if (response.data.status == 200 || response.data.status == 201) {

        //        return response.data.payload

        //     }

        // } catch ({ response }) {

        //     this.state.$app.toastError('invoice/save_manual_payment', response)

        //     return false
        // }
    },
    // async send_notification({ }, invoices) {

    //     try {

    //         const response = await Http().post('/campaign', invoices)

    //         if (response.status == 200 || response.status == 201) {

    //             this.state.$app.toastSuccess('Notificação enviada com sucesso')

    //         }

    //     } catch ({ response }) {

    //         this.state.$app.toastError('invoice/send_notification', response)

    //     }

    //     return true

    // },

}
const mutations = {

    ["SET"]: (state, data) => {
        state[data.state] = data.data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}