export default
    [
        {
            path: '/change-password/:user_id',
            name: 'change_password',
            meta: {
                icon: 'file-code',
                title: 'Automações'
            },
            component: () => import('@/views/change_password/Index')
        }

    ]