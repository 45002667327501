import { _ } from 'core-js';
import HttpComercial from 'HttpComercial';

const state = {
   
}
const getters = {

}
const actions = {
    async save_lead({  }, lead) {

        try{
            const response = await HttpComercial().post('/lead', lead)
            if(response.status == 201){
                return true
            } else {
                return false
            }
        }catch(e){
            console.log('error', e)
        }
    }   
}
const mutations = {
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}