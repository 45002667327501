import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

const childrenRoutes = [];

const requireModules = require.context('@/views', true, /\.js$/);

requireModules.keys().forEach(fileName => {

  const folders = fileName.split('/')

  if (folders[folders.length - 1] == 'index.js' && folders[folders.length - 2] == 'router') {

    childrenRoutes.push(...requireModules(fileName).default)

  }
})

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Index,
    children:[
      ...childrenRoutes
    ]
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
