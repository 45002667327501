import { _ } from 'core-js';
import Http from 'Http';
import axios from 'axios'

const state = {

}
const getters = {

}
const actions = {
    async save_password({ }, password) {
        const response = await Http().post('/authenticate/change-password', password)
        console.log(response)
        if (response.data.status == 200) {
            return true
        } else {
            return false
        }
    }
}
const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}