const requireModules = require.context('@/plugins', true, /\.js$/);

requireModules.keys().forEach(plugin => {

    const [,pluginName] = plugin.split('/')

    if(pluginName !== 'index.js'){

        require (`${plugin}`)

    }


    // const folders = fileName.split('/')

    // if (folders[folders.length - 1] == 'index.js' && folders[folders.length - 2] == 'router') {

    //   if (manualInjectModules.includes(folders[1])) {

    //     childrenRoutes.push(...requireModules(fileName).default)

    //   }
    // }
})